import { useEffect } from "react";
import { storage } from "@divide/ois-react-framework/common";
import storageKeys from "constants/storageKeys";

export default function useSetWebshopCodeToStorage(cdnDomain) {
    useEffect(() => {
        if (!cdnDomain) return;
        storage.set(storageKeys.cdnDomain, cdnDomain);
    }, [cdnDomain]);
}

const deDe = {
    global: {
        action: "Aktion",
        amount: "Anzahl von",
        applyDiscount: "Rabatt anwenden",
        articleNumber: "Artikelnummer",
        articleNumberAbbr: "Artikel Nummer.",
        backToOverview: "Zurück zur Übersicht",
        barcode: "Barcode",
        billingAddress: "Rechnungsadresse",
        branch: "Store",
        cancel: "Absagen",
        color: "Farbe",
        confirm: "Bestätigen",
        country: "Land",
        courier: "Logistik",
        clearFields: "Leere Felder",
        close: "Close",
        city: "Ort",
        changeDiscount: "Rabatt anpassen",
        changeTheDiscount: "Passen Sie den Rabatt an",
        channel: "Verkaufskanal",
        chooseDiscountReason: "Rabattgrund wählen",
        created: "Erstellt",
        currencyAmount: "Menge",
        customerDetails: "Kundendetails",
        customerNumber: "Kundennummer",
        date: "Datum",
        delete: "Löschen",
        deliveryAddress: "Lieferadresse",
        discount: "Rabatt",
        documents: "Unterlagen",
        download: "Download",
        edit: "Herstellen",
        email: "E-Mail Addresse",
        enterSearchQuery: "Bitte geben Sie zuerst eine Suchanfrage ein.",
        general: "Allgemein",
        givenDiscount: "Rabatt gegeben",
        history: "Geschichte",
        houseNumber: "Hausnummer",
        houseNumberExtension: "Zusatz",
        id: "ID",
        iban: "IBAN",
        lastName: "Nachname",
        moreResults: "Mehr Ergebnisse",
        mr: "Herr.",
        mrs: "Frau.",
        name: "Name",
        nameRecipient: "Namensempfänger",
        noDocuments: "Keine Dokumente.",
        noHistoryAvailable: "Keine Vorgeschichte bekannt.",
        noOrdersFound: "Keine Bestellungen gefunden",
        noProductImageAvailable: "Für dieses Produkt ist kein Foto verfügbar.",
        noShippingInformationAvailable: "Keine Versandinformationen verfügbar",
        order: "Bestellung",
        orderDate: "Bestelldatum",
        orderDateFrom: "Bestelldatum von",
        orderDateUntill: "Bestelldatum bis",
        orderId: "Bestellungsnummer",
        orderAmount: "Bestellbetrag",
        orderDetails: "Bestelldetails",
        orderNumber: "Bestellnummer",
        orderSearch: "Suchreihenfolge",
        originalDiscount: "Ursprünglicher Rabatt",
        packstation: "Packstation",
        paymentMethod: "Zahlungsmethode",
        paymentStatus: "Zahlungsstatus",
        paymentReference: "Zahlungsreferenz",
        postalCode: "Postleitzahl",
        price: "Preis",
        productInformation: "Produktinformation",
        recirculate: "Rezirkulieren",
        refund: "Erstattung",
        results: "Ergebnisse",
        removeDiscount: "Rabatt entfernen",
        save: "Speichern",
        saveInformation: "Daten speichern",
        shipmentNumber: "Sendungsnummer",
        shippingAgentCode: "Versandcode",
        shippingCosts: "Portokosten",
        shippingDateTime: "Versanddatum/-zeit",
        shippingStatus: "Versandstatus",
        size: "Größe",
        status: "Status",
        street: "Straße",
        submit: "Senden",
        subtotal: "Zwischensumme",
        total: "Gesamt",
        trackAndTrace: "Track & Trace",
        viewOrder: "Ansehen",
        viewProducts: "Produkte ansehen",
        xOrdersFound: (amount = 0) => `${amount} Bestellung(en) gefunden`,
        subTotalOfOrder: "Zwischensumme der Bestellung",
        discountOfOrder: "Rabatt der Bestellung",
        paid: "Bezahlt",
        discountFromCustomerService: "Rabatt vom Kundenservice",
        view: "Zu betrachten",
        statusLabel: "Statusetikett",
        undeliverable: "Unzustellbar",
        process: "Verfahren"
    },
    navigation: {
        searchOrders: "Suchreihenfolge"
    },
    country: {
        be: "Belgien",
        de: "Deutschland",
        nl: "Niederlanded"
    },
    messages: {
        error: {
            discountApplied: "Beim Anwenden der Rabatte ist etwas schief gelaufen.",
            genericStatusMessage: (status) => `Etwas ist schief gelaufen (${status})`,
            noAddressFoundByPostcodeHouseNumber: "Mit der aktuellen Postleitzahl und Hausnummer wurde kein Straßen- und/oder Ortsname gefunden.",
            somethingWentWrongWithAddress: "Leider ist etwas schief gelaufen. Bearbeiten Sie die Adresse.",
            orderCirculation: "Beim Weiterleiten der Bestellung ist etwas schief gelaufen.",
            orderLinesCancelled: "Beim Stornieren der Bestellpositionen ist etwas schief gelaufen.",
            undefinedError: "Etwas ist schief gelaufen, bitte wenden Sie sich an Ihren Systemadministrator."
        },
        info: {
            enterSearchQuery: "Bitte geben Sie zuerst eine Suchanfrage ein.",
            recirculateOrder: "Möchten Sie die Bestellung wirklich wiederholen?",
            cancelArticles: "Möchten Sie die ausgewählten Artikel wirklich stornieren?",
            noFurtherResultsFound: "Keine weiteren Ergebnisse gefunden."
        },
        loading: {
            applyingDiscount: "Anwendung des Rabatts...",
            cancellingArticles: "Artikel stornieren..",
            getOrder: "Auftrag wird geladen...",
            default: "Belastung..."
        },
        status: {
            sent: "Gesendet",
            canceled: "Abgesagt",
            delayed: "Verspätet",
            retour: "Rückgabe",
            exchanged: "Ausgetauscht",
            delivered: "Geliefert",
            pickedUp: "Abgeholt",
            sepaCreated: "SEPA erstellt",
            partiallySent: "Teilweise verschickt",
            partiallyCanceled: "Teilweise storniert",
            partiallyDelayed: "teilweise verzögert",
            partiallyReturned: "Teilrückgabe",
            partialExchanged: "Teil getauscht",
            partialDelivered: "Teilweise geliefert",
            partialPickup: "Teilweise gesammelt",
            partialSepaCreated: "Teilweise erstellt SEPA",
            pending: "In Behandlung",
            returned: "Ist zurückgekommen",
            unknown: "Unbekanntd"
        },
        success: {
            discountApplied: "Der Rabatt wurde eingeführt.",
            orderLinesCancelled: "Die Auftragspositionen wurden storniert.",
            removedFromCart: (productName) => `Das Produkt "${productName}" wurde aus Ihrem Warenkorb entfernt.`,
            newOrderCirculation: "Ordnung ist in einem neuen Umlauf.",
            refundVarients: "Die ausgewählten Varianten wurden erfolgreich für eine Rückerstattung registriert.",
            savedDetails: "Daten wurden erfolgreich gespeichertn"
        },
        validation: {
            fieldRequired: "Dieses Feld ist erforderlich.",
            bankAccountHolder: `Das Feld "Empfängername" ist erforderlich.`,
            iban: `Das Feld "IBAN" muss eine gültige IBAN enthalten.`,
            email: `Das Feld "E-Mail-Adresse" muss eine gültige E-Mail-Adresse enthalten.`
        }
    }
};

export default deDe;

const env = process.env;

const auth0Config = {
    domain: "divide.eu.auth0.com",
    scopes: "profile openid email chooseBranch admin get:settings get:endpoints get:addresscompletion get:order put:order put:ordershipment get:ordershipment get:orderdocuments get:circulation put:circulation get:configuration",
    editorScopes: ["put:order", "put:ordershipment", "put:circulation"],
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    audience: env.REACT_APP_AUTH0_AUDIENCE
}

export default auth0Config;
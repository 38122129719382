const frFr = {
    global: {
        action: "Action",
        amount: "Nombre de",
        applyDiscount: "Appliquer la remise",
        articleNumber: "Numéro de l'article",
        articleNumberAbbr: "Numéro d'article",
        backToOverview: "Retour à l'aperçu",
        barcode: "Code à barre",
        billingAddress: "Adresse de facturation",
        branch: "Store",
        cancel: "Annuler",
        color: "Couleur",
        confirm: "Confirmer",
        country: "Pays",
        courier: "Logistique",
        clearFields: "Effacer les champs",
        close: "Fermer",
        city: "Endroit",
        changeDiscount: "Ajuster la remise",
        changeTheDiscount: "Ajuster la remise",
        channel: "Canal de vente",
        chooseDiscountReason: "Choisissez la raison de la remise",
        created: "Établi",
        currencyAmount: "Montant",
        customerDetails: "Détails du client",
        customerNumber: "Numéro client",
        date: "Date",
        delete: "Retirer",
        deliveryAddress: "Adresse de livraison",
        discount: "Réduction",
        documents: "Documents",
        download: "Download",
        edit: "Modifier",
        email: "Adresse e-mail",
        enterSearchQuery: "Veuillez d'abord entrer une requête de recherche.",
        general: "Général",
        givenDiscount: "Remise accordée",
        history: "Histoire",
        houseNumber: "Numéro de maison",
        houseNumberExtension: "Ajout",
        id: "ID",
        iban: "IBAN",
        lastName: "Nom de famille",
        moreResults: "Plus de résultats",
        mr: "M.",
        mrs: "Mme.",
        name: "Nom",
        nameRecipient: "Nom du destinataire",
        noDocuments: "Aucun document.",
        noHistoryAvailable: "Pas d'historique connu.",
        noOrdersFound: "Aucune ordre trouvée",
        noProductImageAvailable: "Il n'y a pas de photo disponible pour ce produit.",
        noShippingInformationAvailable: "Aucune information d'expédition disponible",
        order: "Ordre",
        orderDate: "Date de ordre",
        orderDateFrom: "Date de ordre du",
        orderDateUntill: "Date de ordre jusqu'au",
        orderId: "Numéro de ordre",
        orderAmount: "Montant de la ordre",
        orderDetails: "Détails de la ordre",
        orderNumber: "Numéro de ordre",
        orderSearch: "Ordre de recherche",
        originalDiscount: "Remise d'origine",
        packstation: "Packstation",
        paymentMethod: "Mode de paiement",
        paymentStatus: "Statut de paiement",
        paymentReference: "Référence de paiement",
        postalCode: "Code postal",
        price: "Prix",
        productInformation: "Information produit",
        recirculate: "Recirculer",
        refund: "Remboursement",
        results: "Résultats",
        removeDiscount: "Supprimer la remise",
        save: "sauvegarder",
        saveInformation: "La sauvegarde des données",
        shipmentNumber: "Numéro d'expédition",
        shippingAgentCode: "Code d'expédition",
        shippingCosts: "Frais d'expédition",
        shippingDateTime: "Date/heure d'expédition",
        shippingStatus: "Statut d'envoi",
        size: "Taille",
        status: "Statut",
        street: "Rue",
        submit: "Envoyez",
        subtotal: "Total",
        total: "Total",
        trackAndTrace: "suivi des envois",
        viewOrder: "Voir l'ordre",
        viewProducts: "Voir les produits",
        xOrdersFound: (amount = 0) => `${amount} ordre(s) trouvée(s)`,
        subTotalOfOrder: "Sous-total de la commande",
        discountOfOrder: "Remise de la commande",
        paid: "Payé",
        discountFromCustomerService: "Remise du service client",
        view: "Regarder",
        statusLabel: "Étiquette d'état",
        undeliverable: "Non livrable",
        process: "Traiter"
    },
    navigation: {
        searchOrders: "Ordre de recherche"
    },
    country: {
        be: "Belgique",
        de: "Allemagne",
        nl: "Pays-Basd"
    },
    messages: {
        error: {
            discountApplied: "Une erreur s'est produite lors de l'application des remises.",
            genericStatusMessage: (status) => `Quelque chose s'est mal passé (${status})`,
            noAddressFoundByPostcodeHouseNumber: "Aucun nom de rue et/ou de lieu n'a été trouvé avec le code postal et le numéro de maison actuels.",
            somethingWentWrongWithAddress: "Malheureusement, quelque chose s'est mal passé. Modifiez l'adresse.",
            orderCirculation: "Quelque chose s'est mal passé lors de la diffusion de la ordre.",
            orderLinesCancelled: "Une erreur s'est produite lors de l'annulation des lignes de ordre.",
            undefinedError: "Quelque chose s'est mal passé, veuillez contacter votre administrateur système."
        },
        info: {
            enterSearchQuery: "Veuillez d'abord entrer une requête de recherche.",
            recirculateOrder: "Êtes-vous sûr de vouloir redistribuer la ordre?",
            cancelArticles: "Êtes-vous sûr de vouloir annuler les éléments sélectionnés?",
            noFurtherResultsFound: "Aucun autre résultat trouvé."
        },
        loading: {
            applyingDiscount: "Application de la réduction...",
            cancellingArticles: "Annulation des articles..",
            getOrder: "Chargement de la ordre...",
            default: "Charger..."
        },
        status: {
            sent: "Expédié",
            canceled: "Annulé",
            delayed: "Différé",
            retour: "Revenir",
            exchanged: "Échangé",
            delivered: "Livré",
            pickedUp: "Ramassé",
            sepaCreated: "SEPA créé",
            partiallySent: "Partiellement expédié",
            partiallyCanceled: "Partiellement annulé",
            partiallyDelayed: "Partiellement retardé",
            partiallyReturned: "Retour de pièce",
            partialExchanged: "Pièce échangée",
            partialDelivered: "Partiellement livré",
            partialPickup: "Partiellement collecté",
            partialSepaCreated: "SEPA partiellement créé",
            pending: "En traitement",
            returned: "Revenu",
            unknown: "Inconnued"
        },
        success: {
            discountApplied: "La réduction a été mise en place.",
            orderLinesCancelled: "Les lignes de ordre ont été annulées.",
            removedFromCart: (productName) => `Le produit "${productName}" a été supprimé de votre panier.`,
            newOrderCirculation: "La ordre est dans une nouvelle circulation.",
            refundVarients: "Les variantes sélectionnées ont été enregistrées avec succès pour un remboursement.",
            savedDetails: "Les données ont été enregistrées avec succès"
        },
        validation: {
            fieldRequired: "Ce champ est obligatoire.",
            bankAccountHolder: `Le champ "Nom du destinataire" est obligatoire.`,
            iban: `Le champ "IBAN" doit contenir un IBAN valide.`,
            email: `Le champ "Adresse e-mail" doit contenir une adresse e-mail valide.`
        }
    }
};

export default frFr;

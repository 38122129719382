const nlNl = {
    global: {
        action: "Actie",
        amount: "Aantal",
        applyDiscount: "Korting toepassen",
        articleNumber: "Artikelnummer",
        articleNumberAbbr: "Artikelnr.",
        backToOverview: "Terug naar overzicht",
        barcode: "Barcode",
        billingAddress: "Factuuradres",
        branch: "Vestiging",
        cancel: "Annuleren",
        color: "Kleur",
        confirm: "Bevestigen",
        country: "Land",
        courier: "Logistiek",
        clearFields: "Velden legen",
        close: "Sluiten",
        city: "Plaats",
        changeDiscount: "Korting aanpassen",
        changeTheDiscount: "Pas de korting aan",
        channel: "Verkoopkanaal",
        chooseDiscountReason: "Kies kortingsreden",
        created: "Aangemaakt",
        currencyAmount: "Bedrag",
        customerDetails: "Klantgegevens",
        customerNumber: "Klantnummer",
        date: "Datum",
        delete: "Verwijderen",
        deliveryAddress: "Afleveradres",
        discount: "Korting",
        documents: "Documenten",
        download: "Download",
        edit: "Bewerken",
        email: "E-mailadres",
        enterSearchQuery: "Vul eerst een zoekopdracht in.",
        general: "Algemeen",
        givenDiscount: "Gegeven korting",
        history: "Geschiedenis",
        houseNumber: "Huisnummer",
        houseNumberExtension: "Toevoeging",
        id: "ID",
        iban: "IBAN",
        lastName: "Achternaam",
        moreResults: "Meer resultaten",
        mr: "Dhr.",
        mrs: "Mevr.",
        name: "Naam",
        nameRecipient: "Naam ontvanger",
        noDocuments: "Geen documenten.",
        noHistoryAvailable: "Geen geschiedenis bekend.",
        noOrdersFound: "Geen bestellingen gevonden",
        noProductImageAvailable: "Er is geen foto beschikbaar voor dit product.",
        noShippingInformationAvailable: "Geen verzendgegevens beschikbaar",
        order: "Bestelling",
        orderDate: "Besteldatum",
        orderDateFrom: "Besteldatum van",
        orderDateUntill: "Besteldatum t/m",
        orderId: "Order ID",
        orderAmount: "Orderbedrag",
        orderDetails: "Ordergegevens",
        orderNumber: "Ordernummer",
        orderSearch: "Bestelling zoeken",
        originalDiscount: "Originele korting",
        packstation: "Packstation",
        paymentMethod: "Betaalmethode",
        paymentStatus: "Betaalstatus",
        paymentReference: "Betalingskenmerk",
        postalCode: "Postcode",
        price: "Prijs",
        productInformation: "Productinformatie",
        recirculate: "Hercirculeren",
        refund: "Terugbetalen",
        results: "Resultaten",
        removeDiscount: "Korting verwijderen",
        save: "Opslaan",
        saveInformation: "Gegevens opslaan",
        shipmentNumber: "Verzendnummer",
        shippingAgentCode: "Verzendcode",
        shippingCosts: "Verzendkosten",
        shippingDateTime: "Verzenddatum/tijd",
        shippingStatus: "Verzendstatus",
        size: "Maat",
        status: "Status",
        street: "Straat",
        submit: "Versturen",
        subtotal: "Subtotaal",
        total: "Totaal",
        trackAndTrace: "Track & Trace",
        viewOrder: "Bestelling bekijken",
        viewProducts: "Producten bekijken",
        xOrdersFound: (amount = 0) => `${amount} bestelling(en) gevonden`,
        subTotalOfOrder: "Subtotaal van de order",
        discountOfOrder: "Korting van de order",
        paid: "Betaald",
        discountFromCustomerService: "Korting vanuit klantenservice",
        view: "Bekijken",
        statusLabel: "Statuslabel",
        undeliverable: "Manco",
        process: "Verwerken"
    },
    navigation: {
        searchOrders: "Bestelling zoeken"
    },
    country: {
        be: "België",
        de: "Duitsland",
        nl: "Nederland"
    },
    messages: {
        error: {
            discountApplied: "Er is iets fout gegaan bij het doorvoeren van de kortingen.",
            genericStatusMessage: (status) => `Er is iets fout gegaan (${status})`,
            noAddressFoundByPostcodeHouseNumber: "Er is geen straat- en/of plaatsnaam gevonden met de huidige postcode en huisnummer.",
            somethingWentWrongWithAddress: "Er is helaas iets misgegaan. Pas het adres aan.",
            orderCirculation: "Er is iets fout gegaan bij het circuleren van de order.",
            orderLinesCancelled: "Er is iets fout gegaan bij het annuleren van de orderregels.",
            undefinedError: "Er is iets fout gegaan, neem contact op met de systeembeheerder."
        },
        info: {
            enterSearchQuery: "Vul eerst een zoekopdracht in.",
            recirculateOrder: "Weet u zeker dat u de order wilt hercirculeren?",
            cancelArticles: "Weet u zeker dat u de gekozen artikelen wilt annuleren?",
            noFurtherResultsFound: "Geen verdere resultaten gevonden."
        },
        loading: {
            applyingDiscount: "Bezig met doorvoeren van de korting...",
            cancellingArticles: "Bezig met het annuleren van de artikelen..",
            getOrder: "Bestelling wordt geladen...",
            default: "Laden..."
        },
        status: {
            sent: "Verzonden",
            canceled: "Geannuleerd",
            delayed: "Vertraagd",
            retour: "Retour",
            exchanged: "Geruild",
            delivered: "Geleverd",
            pickedUp: "Opgehaald",
            sepaCreated: "SEPA aangemaakt",
            partiallySent: "Deels verzonden",
            partiallyCanceled: "Deels geannuleerd",
            partiallyDelayed: "Deels vertraagd",
            partiallyReturned: "Deels geretourneerd",
            partialExchanged: "Deels geruild",
            partialDelivered: "Deels geleverd",
            partialPickup: "Deels opgehaald",
            partialSepaCreated: "SEPA deels aangemaakt",
            pending: "In behandeling",
            returned: "Geretourneerd",
            unknown: "Onbekend"
        },
        success: {
            discountApplied: "De korting is doorgevoerd.",
            orderLinesCancelled: "De orderregels zijn geannuleerd.",
            removedFromCart: (productName) => `Het product "${productName}" is verwijderd uit uw winkelwagen.`,
            newOrderCirculation: "Order zit in een nieuwe circulatie.",
            refundVarients: "De geselecteerde varianten zijn succesvol aangemeld voor terugbetaling.",
            savedDetails: "Gegevens zijn succesvol opgeslagen"
        },
        validation: {
            fieldRequired: "Dit veld is verplicht.",
            bankAccountHolder: `Het veld "Naam ontvanger" is verplicht.`,
            iban: `Het veld "IBAN" moet een geldige IBAN bevatten.`,
            email: `Het veld "E-mailadres" moet een geldige e-mailadres bevatten.`
        }
    }
};

export default nlNl;

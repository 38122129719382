const enGb = {
    global: {
        action: "Action",
        amount: "Amount",
        applyDiscount: "Apply discount",
        articleNumber: "Article number",
        articleNumberAbbr: "Article no.",
        backToOverview: "Back to overview",
        barcode: "Barcode",
        billingAddress: "Billing address",
        branch: "Branch",
        cancel: "Cancel",
        color: "Color",
        confirm: "Confirm",
        country: "Country",
        courier: "Courier",
        clearFields: "Clear fields",
        close: "Close",
        city: "Place",
        changeDiscount: "Change discount",
        changeTheDiscount: "Change the discount",
        channel: "Channel",
        chooseDiscountReason: "Choose discount reason",
        created: "Created",
        currencyAmount: "Amount",
        customerDetails: "Customer details",
        customerNumber: "Customer number",
        date: "Date",
        delete: "Remove",
        deliveryAddress: "Delivery address",
        discount: "Discount",
        documents: "Documents",
        download: "Download",
        edit: "Edit",
        email: "Email",
        enterSearchQuery: "Please enter a search query first.",
        general: "General",
        givenDiscount: "Discount given",
        history: "History",
        houseNumber: "House number",
        houseNumberExtension: "Addition",
        id: "ID",
        iban: "IBAN",
        lastName: "Last name",
        moreResults: "More results",
        mr: "Mr.",
        mrs: "Mrs.",
        name: "Name",
        nameRecipient: "Name recipient",
        noDocuments: "No documents.",
        noHistoryAvailable: "No history known.",
        noOrdersFound: "No orders found",
        noProductImageAvailable: "There is no photo available for this product.",
        noShippingInformationAvailable: "No shipping information available",
        order: "Order",
        orderDate: "Order date",
        orderDateFrom: "Order date from",
        orderDateUntill: "Order date up to",
        orderId: "Order ID",
        orderAmount: "Order amount",
        orderDetails: "Order details",
        orderNumber: "Order number",
        orderSearch: "Search order",
        originalDiscount: "Original discount",
        packstation: "Packstation",
        paymentMethod: "Payment method",
        paymentStatus: "Payment status",
        paymentReference: "Payment reference",
        postalCode: "Postal Code",
        price: "Price",
        productInformation: "Product information",
        recirculate: "Recirculate",
        refund: "Refund",
        results: "Results",
        removeDiscount: "Remove discount",
        save: "Save",
        saveInformation: "Save data",
        shipmentNumber: "Shipping number",
        shippingAgentCode: "Shipping code",
        shippingCosts: "Shipping costs",
        shippingDateTime: "Ship date/time",
        shippingStatus: "Shipping status",
        size: "Size",
        status: "Status",
        street: "Street",
        submit: "Submit",
        subtotal: "Subtotal",
        total: "Total",
        trackAndTrace: "Track & Trace",
        viewOrder: "View order",
        viewProducts: "View products",
        xOrdersFound: (amount = 0) => `${amount} order(s) found`,
        subTotalOfOrder: "Subtotal of the order",
        discountOfOrder: "Discount of the order",
        paid: "Paid",
        discountFromCustomerService: "Discount from customer service",
        view: "View",
        statusLabel: "Status label",
        undeliverable: "Undeliverable",
        process: "Process"
    },
    navigation: {
        searchOrders: "Search order"
    },
    country: {
        be: "Belgium",
        de: "Germany",
        nl: "The Netherlands"
    },
    messages: {
        error: {
            discountApplied: "Something went wrong while applying the discounts.",
            genericStatusMessage: (status) => `Something went wrong (${status})`,
            noAddressFoundByPostcodeHouseNumber: "No street and/or place name was found with the current zip code and house number.",
            somethingWentWrongWithAddress: "Unfortunately something went wrong. Edit the address.",
            orderCirculation: "Something went wrong while circulating the order.",
            orderLinesCancelled: "Something went wrong while canceling the order lines.",
            undefinedError: "Something went wrong, please contact your system administrator."
        },
        info: {
            enterSearchQuery: "Please enter a search query first.",
            recirculateOrder: "Are you sure you want to recirculate the order?",
            cancelArticles: "Are you sure you want to cancel the selected items?",
            noFurtherResultsFound: "No further results found."
        },
        loading: {
            applyingDiscount: "Applying discount...",
            cancellingArticles: "Canceling items..",
            getOrder: "Loading order...",
            default: "Loading..."
        },
        status: {
            sent: "Sent",
            canceled: "Cancelled",
            delayed: "Delayed",
            retour: "Return",
            exchanged: "Exchanged",
            delivered: "Delivered",
            pickedUp: "Picked up",
            sepaCreated: "SEPA created",
            partiallySent: "Partially shipped",
            partiallyCanceled: "Partially cancelled",
            partiallyDelayed: "Partially delayed",
            partiallyReturned: "Partially returned",
            partialExchanged: "Partially exchanged",
            partialDelivered: "Partially delivered",
            partialPickup: "Partially picked up",
            partialSepaCreated: "SEPA Partially created",
            pending: "Pending",
            returned: "Returned",
            unknown: "Unknown"
        },
        success: {
            discountApplied: "The discount has been applied.",
            orderLinesCancelled: "The order lines have been cancelled.",
            removedFromCart: (productName) => `The product "${productName}" is removed from your cart.`,
            newOrderCirculation: "Order is in a new circulation.",
            refundVarients: "The selected variants have been successfully registered for a refund.",
            savedDetails: "Data has been saved successfully"
        },
        validation: {
            fieldRequired: "This field is required.",
            bankAccountHolder: `The "Name recipient" field is required.`,
            iban: `The "IBAN" field must contain a valid IBAN.`,
            email: `The "Email Address" field must contain a valid email address.`
        }
    }
};

export default enGb;

import { createContext } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslations, useResolveRoutesByAuthentication } from "@divide/ois-react-hooks";
import { useAuth0 } from "@divide/ois-react-components";
import { useCultureCode, useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess } from "@divide/retailsuite-react-hooks";
import { Loader } from "@divide/retailsuite-react-components";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import { privateRoutes, publicRoutes } from "constants/routes";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import "./App.scss";

export const AppContext = createContext();

export function App() {
    const { routes, element } = useResolveRoutesByAuthentication(useAuth0, useRoutes, privateRoutes, publicRoutes);
    const webshopSettings = useGetSettingsGroup(useAuth0, appConfig, "Webshop");
    const generalConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "General");
    const csConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "CS");

    useSetCdnDomainToStorage(webshopSettings.CdnDomain);

    const { loading } = useAuth0();
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const userAccess = useGetUserHasAccess(useAuth0, 2);

    return loading ? (
        <Loader fullscreen={true} text={translation("messages.loading.default")} />
    ) : (
        <AppContext.Provider
            value={{
                cultureCode,
                setCultureCode,
                translation,
                userAccess,
                webshopSettings,
                generalConfiguration,
                csConfiguration,
                routes
            }}
        >
            {element}
        </AppContext.Provider>
    );
}

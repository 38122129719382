import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "cs_date",
    version: "cs_version",
    checkDiscountTypeSelect: "cs_check-discount-type-select",
    checkDiscountTotal: "cs_check-discount-total",
    valid: "cs_valid",
    searchForm: "cs_search-form",
    cdnDomain: "cs_cdn-domain",  
    addToHomeScreenMessageIsClosed: "cs_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "cs_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "cs_has-used-home-screen-app",
    orderSearchFormValues: "cs_order-search-form-values",
    updateAvailable: "cs_update_available",
    ...sharedStorageKeys
};

export default storageKeys;
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { sharedRoutes } from "./sharedRoutes";

const OrderOverviewPage = lazy(() => import("pages/OrderOverviewPage"));
const OrderDetailPage = lazy(() => import("pages/OrderDetailPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "orderOverview",
                path: "/",
                title: "navigation.searchOrders",
                index: true,
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <OrderOverviewPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "orderDetail",
                path: "/order/:orderId",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <OrderDetailPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "/profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            ...sharedRoutes
        ]
    },

];
